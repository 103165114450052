import { chatTypes } from '../constants/actionTypes';
import { getFromLocalStorage, setLocalStorage } from '../utils/localStorage';

const initialState = {
  number: getFromLocalStorage('number') || '',
  sessionChatId: '',
  loading: false,
  senderLoading: false,
  weddings: [],
  guests: [],
  guest: {},
  messages: [],
  chatStatus: 'expired',
};

const chatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case chatTypes.SET_NUMBER:
      setLocalStorage('number', action.payload);
      return {
        ...state,
        number: action.payload,
      };

    case chatTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case chatTypes.SET_SENDER_LOADING:
      return {
        ...state,
        senderLoading: true,
      };

    case chatTypes.SET_SESSION_ID:
      return {
        ...state,
        sessionChatId: Date.now(),
        messages: [],
      };

    case chatTypes.SET_WEDDING_LIST:
      return {
        ...state,
        loading: false,
        weddings: action.payload,
      };

    case chatTypes.SET_WEDDING:
      return {
        ...state,
        loading: false,
        wedding: action.payload,
      };

    case chatTypes.SET_GUEST_LIST:
      return {
        ...state,
        guests: action.payload,
      };

    case chatTypes.SET_GUEST:
      return {
        ...state,
        guest: action.payload,
        messages: [],
      };

    case chatTypes.SET_MESSAGE_HISTORY:
      return {
        ...state,
        loading: false,
        messages: action.payload,
        chatStatus: action.chatStatus || state.chatStatus,
      };

    case chatTypes.SEND_MESSAGE:
      return {
        ...state,
        senderLoading: false,
        messages: [...state.messages, action.payload],
      };

    case chatTypes.RECEIVE_MESSAGE:
      return {
        ...state,
        loading: false,
        senderLoading: false,
        messages: [...state.messages, action.payload],
      };
    default:
      return state;
  }
};

export default chatReducer;
