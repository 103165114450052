import React from 'react';
import styled from 'styled-components';

const MessageContainer = styled.div<{ isSender: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${({ isSender }) => (isSender ? 'right' : 'left')};
`;

const Text = styled.div<{ isSender: boolean }>`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  background: ${({ isSender }) => (isSender ? '#F3F4F6FF' : '#924758')};
  color: ${({ isSender }) => (isSender ? '#000' : '#fff')};
  text-align: ${({ isSender }) => (isSender ? 'right' : 'left')};
  width: fit-content;

  ${({ isSender }) =>
    isSender ? 'border-top-right-radius: 0px' : 'border-top-left-radius: 0px'};
`;

const Message = ({
  isSender,
  message,
}: {
  isSender?: boolean;
  message: string;
}) => {
  return (
    <MessageContainer isSender={!!isSender}>
      <Text isSender={!!isSender}>{message}</Text>
    </MessageContainer>
  );
};

export default Message;
