export default function NextArrowIcon() {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.76541 6.60477L0.765412 11.6048C0.694324 11.671 0.600301 11.7071 0.503151 11.7054C0.406 11.7036 0.313308 11.6643 0.244601 11.5956C0.175895 11.5269 0.136538 11.4342 0.134824 11.337C0.13311 11.2399 0.169172 11.1459 0.235412 11.0748L4.96979 6.33977L0.235412 1.60477C0.169172 1.53368 0.13311 1.43966 0.134824 1.34251C0.136538 1.24536 0.175895 1.15266 0.244601 1.08396C0.313308 1.01525 0.406 0.975894 0.503151 0.97418C0.600301 0.972465 0.694324 1.00853 0.765412 1.07477L5.76541 6.07477C5.83564 6.14508 5.87508 6.24039 5.87508 6.33977C5.87508 6.43914 5.83564 6.53445 5.76541 6.60477Z"
        fill="#333333"
      />
    </svg>
  );
}
