import React from 'react';

const DotLoader = ({ isSender }: { isSender?: boolean }) => {
  return (
    <div className="loading-dots" style={{ textAlign: `${isSender ? 'right': 'left'}` }}>
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
      <div className="loading-dot"></div>
    </div>
  )
}

export default DotLoader
