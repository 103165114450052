type AnyObject = Record<string, any> | undefined;

// Function to convert camelCase to snake_case
const convertCamelToSnake = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

// Function to recursively convert keys from camelCase to snake_case
export const convertKeysToSnakeCase: any = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map((element) => convertKeysToSnakeCase(element));
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        convertCamelToSnake(key),
        convertKeysToSnakeCase(value),
      ])
    );
  }
  return obj;
};

// Function to convert snake_case to camelCase
const convertSnakeToCamel = (str: string): string =>
  str.replace(/(_\w)/g, (match) => match[1].toUpperCase());

// Function to recursively convert keys from snake_case to camelCase
export const convertKeysToCamelCase = (obj: AnyObject): AnyObject => {
  if (Array.isArray(obj)) {
    return obj.map((element) => convertKeysToCamelCase(element)) as AnyObject[];
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: AnyObject = {};
    for (const [key, value] of Object.entries(obj)) {
      const camelCaseKey = convertSnakeToCamel(key);
      newObj[camelCaseKey] = convertKeysToCamelCase(value);
    }
    return newObj;
  }
  return obj;
};
