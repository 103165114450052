import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppDispatch } from '../store';
import { chatTypes } from '../constants/actionTypes';
import { sendMessage } from '../actions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  background: #efb034;
  text-align: left;
  width: fit-content;
  border-top-left-radius: 0px;
`;

const Button = styled.button`
  padding: 12px;
  margin: 8px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
`;

const Selection = () => {
  const dispatch: AppDispatch = useDispatch();

  const sessionChatId = useSelector(
    (state: any) => state.chatReducer.sessionChatId
  );
  const number = useSelector((state: any) => state.chatReducer.number);
  const chatId = `${number}${sessionChatId}`;

  const onSubmit = (e: any, message: string) => {
    e.preventDefault();
    dispatch({
      type: chatTypes.SEND_MESSAGE,
      payload: {
        isSender: true,
        text: message,
      },
    });
    dispatch(sendMessage(chatId, message));
  };

  return (
    <Container>
      Hello! Welcome, your personal room design assistant. How can I help you
      today? I’m here to help you design your dream living space.
      <br />
      <br />
      You have two easy ways to begin: <br />
      <br />
      1. Text: If you prefer describing your design ideas or preferences in
      text, simply type your responses, and I’ll assist you accordingly.
      <br />
      2. Image: If you already have an image of the room you want to redesign,
      simply upload the image and provide any additional details you’d like to
      discuss.
      <br />
      <br />
      Whether you’re a wordsmith or a visual thinker, I’m here to assist you in
      creating the perfect living space. So, how would you like to begin today?
      <br />
      <br />
      <Button onClick={(e) => onSubmit(e, 'text')}>Text</Button>
      <Button onClick={(e) => onSubmit(e, 'image')}>Image</Button>
    </Container>
  );
};

export default Selection;
