import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import Chat from './Chat';
import ChatList from './ChatList';
import { getAllWeddings, getWeddingGuests } from '../actions';
import NumberInput from '../components/NumberInput';
import { CookiesListConstants, getCookie, setCookie } from '../utils/cookies';
import WeddingList from './WeddingList';

const ChatMain = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { weddingId: weddingIdParams } = useParams();
  const selectedWeddingId =
    useSelector((state: any) => state.chatReducer.wedding)?.id ||
    weddingIdParams;
  const [showChat, setShowChat] = useState(false);
  const [isAuthComplete, setAuthComplete] = useState(false);
  const auth_token = searchParams.get('auth_token');
  const authToken = getCookie(CookiesListConstants.AUTH_TOKEN) || auth_token;

  useEffect(() => {
    if (auth_token) {
      setCookie(CookiesListConstants.AUTH_TOKEN, auth_token, 365);
    }
  }, [auth_token]);

  useEffect(() => {
    if ((authToken || isAuthComplete) && !selectedWeddingId) {
      getAllWeddings()(dispatch);
    }
  }, [authToken, isAuthComplete]);

  useEffect(() => {
    if (selectedWeddingId) {
      getWeddingGuests(selectedWeddingId)(dispatch);
    }
  }, [selectedWeddingId, authToken, isAuthComplete]);

  const getChatAndOptions = () => {
    if (!selectedWeddingId) {
      return <WeddingList />;
    }

    if (showChat) {
      return <Chat setShowChat={() => setShowChat(false)} />;
    }

    return <ChatList setShowChat={() => setShowChat(true)} />;
  };

  return (
    <div>
      {authToken || isAuthComplete ? (
        getChatAndOptions()
      ) : (
        <NumberInput setAuthComplete={setAuthComplete} />
      )}
    </div>
  );
};

export default ChatMain;
