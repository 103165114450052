export const chatTypes = {
  SET_NUMBER: 'user/set_number',
  SET_LOADING: 'chat/set_loading',
  SET_SENDER_LOADING: 'chat/set_sender_loading',
  SET_SESSION_ID: 'chat/set_session_id',
  SET_WEDDING_LIST: 'chat/set_wedding_list',
  ERROR_WEDDING_LIST: 'chat/error_wedding_list',
  SET_WEDDING: 'chat/set_wedding',
  SET_GUEST_LIST: 'chat/set_guest_list',
  ERROR_GUEST_LIST: 'chat/error_guest_list',
  SET_GUEST: 'chat/set_guest',
  SET_MESSAGE_HISTORY: 'chat/set_message_history',
  SEND_MESSAGE: 'chat/send_message',
  RECEIVE_MESSAGE: 'chat/receive_message',
};
