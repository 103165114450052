import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ChatInput from '../components/ChatInput';
import Message from '../components/Message';
import Image from '../components/Image';
import DotLoader from '../components/DotLoader';
import Selection from '../components/Selection';
import { MessageType } from '../constants/types';
import { markMessagesAsRead } from '../actions';
import BackArrowIcon from '../assets/svgs/BackArrow';

const Header = styled.div`
  position: fixed;
  top: 0;
  box-sizing: border-box;
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #924758ff;
  padding: 18px 24px;
  color: white;
`;

const Back = styled.div`
  font-weight: 700;
  padding: 2px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 20px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
`;

const MessageContainer = styled.div`
  margin: 80px 20px;
  overflow: hidden;
  text-align: right;
`;

const Chat = ({ setShowChat }: { setShowChat: () => void }) => {
  const { weddingId: weddingIdParams } = useParams();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const guest = useSelector((state: any) => state.chatReducer.guest);
  const messages = useSelector((state: any) => state.chatReducer.messages);
  const senderLoading = useSelector(
    (state: any) => state.chatReducer.senderLoading
  );
  const loading = useSelector((state: any) => state.chatReducer.loading);
  const guestPhoneNumber = guest?.guestPhoneNumber;
  const weddingId =
    useSelector((state: any) => state.chatReducer.wedding)?.id ||
    weddingIdParams;

  const getMessage = (mes: MessageType) => {
    if (mes['content-type'] !== 'text' && mes['content-type'] !== 'template') {
      return;
    }

    if (mes.showSelection) {
      return <Selection />;
    }

    if (mes.isImage) {
      return (
        <Image
          key={`${mes.messageId}${Math.random()}`}
          onLoad={scrollToBottom}
          url={mes.value.body || mes.value.name}
          isSender={mes.isSentByTeam}
        />
      );
    }

    return (
      <Message
        key={`${mes.messageId}${Math.random()}`}
        message={mes?.value?.body || mes.value.name}
        isSender={mes.isSentByTeam}
      />
    );
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();

    if (messages?.length) {
      const reverseMessages = messages.slice().reverse();
      const messageIds = [];
      for (let i = 0; i < reverseMessages.length; i++) {
        const mes = reverseMessages[i];
        if (!mes.isSentByTeam) {
          messageIds.push(mes.messageId);
        } else {
          break;
        }
      }
      if (messageIds.length) {
        markMessagesAsRead(weddingId, guestPhoneNumber, messageIds);
      }
    }
  }, [messages]);

  return (
    <div>
      <Header>
        <Back onClick={setShowChat}>
          <BackArrowIcon />
        </Back>
        <Title>
          {guest?.guestName} - {guest.guestPhoneNumber}
        </Title>
      </Header>
      <MessageContainer>
        {messages?.map((mes: MessageType) => getMessage(mes))}
        {(loading || senderLoading) && <DotLoader isSender={senderLoading} />}
      </MessageContainer>
      <ChatInput />
      <div ref={messagesEndRef} />
    </div>
  );
};

export default Chat;
