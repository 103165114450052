import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { sendOtp, verifyOtp } from '../actions';

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 450px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  margin-left: 10px;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  padding: 10px;
  font-size: 18px;
`;

const Submit = styled.input`
  margin-top: 26px;
  padding: 20px 0px;
  width: 100%;
  color: white;
  background: #924758ff;
  border: none;
  border-radius: 4px;
  font-size: 18px;
`;

const NumberInput = ({
  setAuthComplete,
}: {
  setAuthComplete: (value: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [number, setNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isError, setIsError] = useState(false);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (number?.length !== 10) {
      setIsError(true);
    } else {
      if (step === 0) {
        sendOtp(number)(dispatch);
        setStep(1);
        return;
      }

      const verified = await verifyOtp(number, otp)(dispatch);
      if (verified) {
        setAuthComplete(true);
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        {step === 1 ? (
          <>
            <Label>OTP</Label>
            <Input
              type="tel"
              pattern="\d*"
              maxLength={4}
              minLength={4}
              onFocus={() => setIsError(false)}
              placeholder="Enter your OTP"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
            />
          </>
        ) : (
          <>
            <Label>Mobile Number</Label>
            <Input
              type="tel"
              pattern="\d*"
              maxLength={10}
              minLength={10}
              onFocus={() => setIsError(false)}
              placeholder="Enter your mobile number"
              onChange={(e) => {
                setNumber(e.target.value);
              }}
            />
          </>
        )}
        <br />
        <Submit
          style={{ padding: '10px' }}
          type="submit"
          value={step === 1 ? 'Verify' : 'Send OTP'}
        />
      </Form>
      <br />
      {isError && (
        <div style={{ color: 'red' }}>
          Invalid phone number format. Please enter a 10 digit phone number.
        </div>
      )}
    </Container>
  );
};

export default NumberInput;
