import React from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import './App.css';

import store from './store';
import Home from './views/Home';

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <Routes>
          <Route path="/chat" element={<Home />} />
          <Route path="/chat/:weddingId" element={<Home />} />
        </Routes>
      </div>
    </Provider>
  );
};

export default App;
