export default function BackArrowIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#333333"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2988_7141)">
        <path
          d="M20.8125 11.9999C20.8125 12.1491 20.7532 12.2921 20.6477 12.3976C20.5423 12.5031 20.3992 12.5624 20.25 12.5624H5.10843L10.8975 18.3524C10.9528 18.4039 10.9971 18.466 11.0278 18.535C11.0586 18.604 11.0751 18.6785 11.0764 18.754C11.0778 18.8295 11.0639 18.9045 11.0356 18.9746C11.0073 19.0446 10.9652 19.1082 10.9118 19.1617C10.8584 19.2151 10.7947 19.2572 10.7247 19.2855C10.6546 19.3138 10.5796 19.3277 10.5041 19.3263C10.4286 19.325 10.3541 19.3085 10.2851 19.2777C10.2161 19.247 10.154 19.2027 10.1025 19.1474L3.35249 12.3974C3.24716 12.2919 3.18799 12.1489 3.18799 11.9999C3.18799 11.8508 3.24716 11.7079 3.35249 11.6024L10.1025 4.85238C10.2091 4.75302 10.3502 4.69893 10.4959 4.7015C10.6416 4.70407 10.7806 4.76311 10.8837 4.86617C10.9868 4.96923 11.0458 5.10827 11.0484 5.25399C11.0509 5.39972 10.9969 5.54075 10.8975 5.64739L5.10843 11.4374H20.25C20.3992 11.4374 20.5423 11.4966 20.6477 11.6021C20.7532 11.7076 20.8125 11.8507 20.8125 11.9999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2988_7141">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
