import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { chatTypes } from '../constants/actionTypes';
import { getWeddingGuests } from '../actions';

const ListContainer = styled.div`
  margin: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 6px;
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #323842ff;
`;

const ListHeader = styled.div`
  padding: 12px 16px;
  background: #f6f3f3;
  box-sizing: border-box;
  margin: 0px -10px 8px;
  box-shadow: 0px 3px #e6e6e6;
  text-align: left;
`;

const ListItem = styled.div`
  border-bottom: 1px solid #dbcbcb;
  padding: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
`;

const GoToSymbol = styled.span`
  font-weight: 500;
  font-size: 20px;
`;

const WeddingList = () => {
  const dispatch = useDispatch();
  const weddings = useSelector((state: any) => state.chatReducer.weddings);

  const setWedding = (wedding: any) => {
    if (wedding?.id) {
      dispatch({ type: chatTypes.SET_WEDDING, payload: wedding });
      getWeddingGuests(wedding.id)(dispatch);
    }
  };

  return (
    <ListContainer>
      <HeaderContainer>
        <Header>Wedding List</Header>
      </HeaderContainer>
      <ListHeader>Grrom & Bride Name</ListHeader>
      {weddings?.map((wedding: any) => (
        <ListItem
          key={wedding?.id}
          onClick={() => {
            if (wedding.id) {
              setWedding(wedding);
            }
          }}
        >
          {wedding?.groomFirstName + ' & ' + wedding?.brideFirstName}
          <GoToSymbol>&gt;</GoToSymbol>
        </ListItem>
      ))}
    </ListContainer>
  );
};

export default WeddingList;
