import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ isSender: boolean }>`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  background: ${({ isSender }) => (isSender ? '#F3F4F6FF' : '#efb034')};
  text-align: ${({ isSender }) => (isSender ? 'right' : 'left')};
  width: fit-content;

  ${({ isSender }) =>
    isSender ? 'border-top-right-radius: 0px' : 'border-top-left-radius: 0px'};
`;

const ImageTag = styled.img`
  max-width: 90%;
  max-height: 90vh;
`;

const Image = ({
  isSender,
  url,
  onLoad,
}: {
  isSender?: boolean;
  url: string;
  onLoad: () => void;
}) => {
  return (
    <Container isSender={!!isSender}>
      <ImageTag onLoad={onLoad} alt={url} src={url} />
      {!isSender && <a rel='noreferrer' target='_blank' href={url} download>Download</a>}
    </Container>
  );
};

export default Image;
