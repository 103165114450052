export const CookiesListConstants = {
  AUTH_TOKEN: 'authToken',
};

// Set a cookie
export const setCookie = (name: string, value: string, days: number): void => {
  const expirationDate = new Date();
  const time = days * 24 * 60 * 60 * 1000;
  expirationDate.setTime(expirationDate.getTime() + time);
  const expires = `expires=${expirationDate.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

// For session cookies
export const setSessionCookie = (
  name: string,
  value: string,
  days: number
): void => {
  document.cookie = `${name}=${value};path=/`;
};

// Get a cookie value by name
export const getCookie = (name: string): string | null => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
};

// Delete a cookie by name
export const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
