import axios from 'axios';
import { chatTypes } from '../constants/actionTypes';
import { CookiesListConstants, getCookie, setCookie } from '../utils/cookies';
import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
} from '../utils/transformKeys';

export const sendOtp = (phoneNumber: string) => async (dispatch: any) => {
  try {
    const result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/auth?phone_number=%2B91${phoneNumber}`,
      timeout: 10000,
    });

    return true;
  } catch (error) {
    return false;
  }
};

export const verifyOtp =
  (phoneNumber: string, otp: string) => async (dispatch: any) => {
    try {
      const result = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_HOST}/auth`,
        timeout: 10000,
        data: {
          phone_number: `+91${phoneNumber}`,
          otp: `${otp}`,
        },
      });

      const { data } = result || {};
      const parsedData = convertKeysToCamelCase(data);

      setCookie(
        CookiesListConstants.AUTH_TOKEN,
        parsedData?.result?.authToken || '',
        365
      );

      return true;
    } catch (error) {
      return false;
    }
  };

export const getAllWeddings = () => async (dispatch: any) => {
  dispatch({
    type: chatTypes.SET_LOADING,
  });

  try {
    const result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/weddings/list_all_weddings`,
      timeout: 10000,
      headers: {
        Authorization: getCookie(CookiesListConstants.AUTH_TOKEN),
      },
    });

    const { data } = result || {};
    const parsedData = convertKeysToCamelCase(data);

    dispatch({
      type: chatTypes.SET_WEDDING_LIST,
      payload: parsedData?.result?.created || [],
    });

    return parsedData;
  } catch (error) {
    dispatch({
      type: chatTypes.ERROR_WEDDING_LIST,
      payload: {
        isSender: false,
        text: 'There was an issue in getting weddings info, please try again.',
      },
    });
  }
};

export const getWeddingGuests =
  (weddingId: string) => async (dispatch: any) => {
    dispatch({
      type: chatTypes.SET_LOADING,
    });

    try {
      const result = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_HOST}/chat_api/chat_listing?wedding_id=${weddingId}`,
        timeout: 10000,
        headers: {
          Authorization: getCookie(CookiesListConstants.AUTH_TOKEN),
        },
      });

      const { data } = result || {};
      const parsedData = convertKeysToCamelCase(data);

      dispatch({
        type: chatTypes.SET_GUEST_LIST,
        payload: parsedData?.result?.result || [],
      });

      return parsedData;
    } catch (error) {
      dispatch({
        type: chatTypes.ERROR_GUEST_LIST,
        payload: {
          isSender: false,
          text: 'There was an issue in getting guests info, please try again.',
        },
      });
    }
  };

export const getGuestChat =
  (weddingId: string, guest: any) => async (dispatch: any) => {
    dispatch({
      type: chatTypes.SET_LOADING,
    });
    dispatch({
      type: chatTypes.SET_GUEST,
      payload: guest,
    });

    try {
      const result = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_HOST}/chat_api/user_chat_history?wedding_id=${weddingId}&guest_id=${guest.guestId}&page_number=1`,
        timeout: 10000,
        headers: {
          Authorization: getCookie(CookiesListConstants.AUTH_TOKEN),
        },
      });

      const { data } = result || {};
      const parsedData = convertKeysToCamelCase(data);

      dispatch({
        type: chatTypes.SET_MESSAGE_HISTORY,
        payload: (parsedData?.result?.result || []).reverse(),
        chatStatus: parsedData?.result?.chatStatus || 'expired',
      });

      return parsedData;
    } catch (error) {
      dispatch({
        type: chatTypes.ERROR_GUEST_LIST,
        payload: {
          isSender: false,
          text: 'There was an issue in getting guests info, please try again.',
        },
      });
    }
  };

export const sendMessage =
  (weddingId: string, message: string, isImage?: boolean) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const chatData = state.chatReducer || {};
    const guestData = chatData?.guest || {};
    const messageHistory = chatData?.messages || [];

    dispatch({
      type: chatTypes.SET_LOADING,
    });

    const newMessage = {
      message: {
        to: guestData?.guestPhoneNumber,
        type: 'text',
        text: {
          body: message,
        },
      },
      guestId: guestData?.guestId,
      guestName: guestData?.guestName,
      weddingId,
      timestamp: `${new Date().getTime()}`,
    };

    try {
      const result = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_HOST}/chat_api/send_message`,
        timeout: 10000,
        data: convertKeysToSnakeCase(newMessage),
        headers: {
          Authorization: getCookie(CookiesListConstants.AUTH_TOKEN),
        },
      });

      const { data } = result || {};
      const parsedData = convertKeysToCamelCase(data);

      if (parsedData?.status === 1) {
        dispatch({
          type: chatTypes.SET_MESSAGE_HISTORY,
          payload: [
            ...messageHistory,
            {
              isSentByTeam: true,
              timestamp: newMessage.timestamp,
              'content-type': 'text',
              messageId: Math.random().toString(36).substring(7),
              value: {
                body: message,
              },
            },
          ],
        });
      }
    } catch (error) {
      dispatch({
        type: chatTypes.SET_MESSAGE_HISTORY,
        payload: [
          ...messageHistory,
          {
            isSentByTeam: true,
            timestamp: newMessage.timestamp,
            contentType: 'text',
            messageId: Math.random().toString(36).substring(7),
            value: {
              body: 'There was an error, please try again.',
            },
          },
        ],
      });
    }
  };

export const markMessagesAsRead = async (
  weddingId: string,
  guestPhoneNumber: string,
  messageIds: string[]
) => {
  try {
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/chat_api/weddingId: string, mark_as_read`,
      timeout: 10000,
      headers: {
        Authorization: getCookie(CookiesListConstants.AUTH_TOKEN),
      },
      data: {
        wedding_id: weddingId,
        guest_phone_number: guestPhoneNumber,
        message_ids: messageIds,
      },
    });

    return true;
  } catch (error) {
    return false;
  }
};

export const uploadImage =
  (id: string, imageData: string) => async (dispatch: any) => {
    dispatch({
      type: chatTypes.SET_SENDER_LOADING,
    });

    try {
      const result = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_HOST}/image_to_url`,
        data: { imageData, userId: id },
      });
      const { data } = result || {};

      dispatch({
        type: chatTypes.RECEIVE_MESSAGE,
        payload: {
          isSender: true,
          text: data || '',
          isImage: true,
        },
      });

      dispatch(sendMessage(id, data, true));
    } catch (error) {
      dispatch({
        type: chatTypes.RECEIVE_MESSAGE,
        payload: {
          isSender: true,
          text: 'There was an error, please upload again.',
        },
      });
    }
  };
