const CHATS_KEY = 'chats';

export const setChatInStorage = (id: string, number: string, messages: any[]) => {
  const existingChats = getChatsFromStorage();
  if (!existingChats[number]) {
    existingChats[number] = {};
  }

  existingChats[number][id] = {
    id: `${number}${id || Date.now()}`,
    sessionChatId: id,
    date: Date.now(),
    messages,
  };

  setLocalStorage(CHATS_KEY, JSON.stringify(existingChats));
};

export const getChatsFromStorage = () => {
  return getFromLocalStorage(CHATS_KEY) || {};
};

export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = (key: string) => {
  const value: any = localStorage.getItem(key);
  return JSON.parse(value);
};
